import {
  membersAppDefId,
  myWishlistInstallDefinition,
  ecomAppDefID,
  addressesAppDefId,
  myAdressesInstallDefinition,
  myWalletAppDefId,
  myWalletInstallDefinition,
  orderHistoryPageId,
  wishlistPageId,
  myOrdersInstallDefinition,
  wishlistAppDefId,
} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';

export class DependantApps {
  constructor(
    private readonly sdk: IEditorSdk,
    private readonly appToken: string,
    private readonly biLogger: Logger,
    private readonly options,
    private readonly instance: string
  ) {
    //
  }

  public installApps(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.addCheckout();
      if (this.options && this.options.firstInstall) {
        if (this.options.origin && this.options.origin.type === 'ADI') {
          return resolve();
        } else {
          return this.installMembers().then(resolve, reject);
        }
      } else {
        const membersInstalled = await this.isMembersInstalled();
        if (membersInstalled) {
          //tslint:disable-next-line:no-floating-promises
          this.biLogger.exposureEventForTests({
            type: 'members_is_installed',
            testName: 'express_checkout',
            is_eligible: true,
          });
        } else {
          //tslint:disable-next-line:no-floating-promises
          this.biLogger.exposureEventForTests({
            testName: 'express_checkout',
            is_eligible: false,
          });
        }

        if (membersInstalled) {
          await this.installMembersAreaPages({shouldInstallMyOrders: false});
        }
        resolve();
      }
    });
  }

  public onMembersInstall(): Promise<any> {
    if (this.options.firstInstall) {
      //tslint:disable-next-line:no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_stores',
        testName: 'express_checkout',
        is_eligible: true,
      });
    } else {
      //tslint:disable-next-line:no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_user',
        testName: 'express_checkout',
        is_eligible: true,
      });
    }

    return Promise.all([this.installMembersAreaPages(), this.addCheckout()]);
  }

  private getMembersApi() {
    return this.sdk.application.getPublicAPI(this.appToken, {appDefinitionId: membersAppDefId});
  }

  public async isMembersInstalled(): Promise<boolean> {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: orderHistoryPageId});
  }

  private addCheckout() {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: 'checkout'}).then(isInstalled => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component(this.appToken, {
          appDefinitionId: ecomAppDefID,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  public installMembers(): Promise<void> {
    return this.sdk.application.install(this.appToken, {
      appDefinitionId: membersAppDefId,
      initiatorAppDefinitionId: ecomAppDefID,
    });
  }

  private installWishlistTPA() {
    return fetch('/_api/site-apps/v1/site-apps/bulk-install', {
      method: 'post',
      body: JSON.stringify({
        apps: [{id: wishlistAppDefId}],
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  public async installWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && !isMyWishlistInstalled) {
      const installWishlistTpaPromise = this.installWishlistTPA();

      const installWishlistSectionInMembersPromise = this.getMembersApi().addApplications([
        myWishlistInstallDefinition,
      ]);
      await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
      await this.sdk.document.save();
    }
  }

  public async uninstallWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && isMyWishlistInstalled) {
      await this.getMembersApi().removeMembersAreaPage(myWishlistInstallDefinition.pageId);
      await this.sdk.document.save();
    }
  }

  private async installMembersAreaPages({shouldInstallMyOrders = true} = {}) {
    const [isMyAdressesInstalled, isMyWalletInstalled] = await Promise.all([
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: addressesAppDefId}),
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: myWalletAppDefId}),
    ]);

    const shouldInstallAdresses = !isMyAdressesInstalled;
    const shouldInstallMyWallet = !isMyWalletInstalled;

    const appsToInstall = [
      shouldInstallMyOrders && myOrdersInstallDefinition,
      shouldInstallAdresses && myAdressesInstallDefinition,
      shouldInstallMyWallet && myWalletInstallDefinition,
    ].filter(app => !!app);

    if (appsToInstall.length === 0) {
      return;
    }

    await this.getMembersApi().addApplications(appsToInstall);
    await this.sdk.document.save();
  }

  public async setDependantMembersAreaApps(): Promise<void> {
    const membersAPI = this.getMembersApi();
    return (
      membersAPI &&
      membersAPI.setDependantApps({
        appId: ecomAppDefID,
        dependencies: [myWalletAppDefId, addressesAppDefId],
      })
    );
  }

  public getWishlistPageRef(): Promise<any> {
    return this.getMembersApi().getMembersPageRef({appDefinitionId: ecomAppDefID, appPageId: wishlistPageId});
  }
}
